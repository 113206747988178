@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @media only screen and (max-width: 380px) {
    .pocket-text {
      font-size: 0.7em !important;
    }
  }
}

.button-interaction__checkout {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes textOpacity {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0.2;
  }

  40% {
    opacity: 0.4;
  }

  60% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flexContainer {
  0% {
    justify-content: center;
  }

  100% {
    justify-content: space-around;
  }
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f5f5f5;
  min-height: 100%;
  min-height: -webkit-fill-available;

  // SCROLLBAR STYLES //
  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #71903E #F4F3F3;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: #F4F3F3;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #71903E;
    border-radius: 20px;
    border: 3px solid #F4F3F3;
  }

  // SCROLLBAR STYLES //

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
    text-align: center;
    caret-color: #71903E;
  }

  .custom_checkbox_container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    height: 25px;

    input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
      cursor: pointer;

      &:focus-visible + .custom_checkmark {
        border-color: #71903E;
      }
    }

    .custom_checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #fff;
      border: 1px solid #000;
      border-radius: 5px;

      &.on_error {
        border: 1px solid #B22234;
      }
    }

    input:checked ~ .custom_checkmark {
      background-color: #fff;
    }

    .custom_checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked ~ .custom_checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .custom_checkmark:after {
      left: 9px;
      top: 5px;
      width: 6px;
      height: 11px;
      border: solid #000;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }


  .smarch_checkbox {
    &.smarch_checkbox_size {
      width: 44px;
      height: 44px;
    }
  }

  .hover_brightness:hover {
    background-color: #556c2f !important;
  }

  .hover_primary:hover {
    color: #71903E !important;
  }

  .custom_danger_bg {
    color: #fff !important;
    border-color: #E30000 !important;
    background-color: #E30000 !important;
  }

  .custom_danger_border {
    border-color: #E30000 !important;
  }

  .mobile_top_bar {
    z-index: 999;
    height: 86px;
    width: 100%;
    box-shadow: 0 0 40px rgba(53, 52, 63, 0.2);

    &.summary_top_bar {
      height: 56px;
    }

    .mobile_header_active_tab_border {
      position: absolute;
      height: 2px;
      background-color: #71903E;
      bottom: -5px;
      left: 0;
      width: 100%;
    }
  }

  .custom_question_mark_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    background-color: #71903E;
    color: #fff;
    border-radius: 50%;
    margin-right: 5px;
  }

  .custom_dimension_input_container {
    .custom_minus_dimension_button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 33px;
      height: 35px;
      background-color: #fff;
      color: #393B42;
      border: 1px solid #DFDFE1;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      font-weight: 600;
    }

    .custom_dimension_input {
      width: 112px;
      border-top: 1px solid #DFDFE1;
      border-bottom: 1px solid #DFDFE1;
      border-radius: 0;
    }

    .custom_dimension_input:focus {
      outline: none;
    }

    .custom_plus_dimension_button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 33px;
      height: 35px;
      background-color: #fff;
      color: #393B42;
      border: 1px solid #DFDFE1;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      font-weight: 600;
    }

    &:focus-within {
      .custom_minus_dimension_button {
        background-color: #71903E;
        color: #fff;
        border-color: #71903E;
      }

      .custom_dimension_input {
        border-color: #71903E;
      }

      .custom_plus_dimension_button {
        background-color: #71903E;
        color: #fff;
        border-color: #71903E;
      }
    }
  }

  .custom_beams_number_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F4F3F3;
    padding: 1.5rem 0;
    border-radius: 6px;
  }

  // Modals
  .home_arrow_mobile_modal_trigger {
    &:focus {
      outline: none;
    }
  }

  .canopy_switch_root {
    position: relative;
    width: 48px;
    height: 25px;
    cursor: pointer;
    border-radius: 9999px;
    caret-color: transparent;
    box-shadow: 0 2px 10px #00000024;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &[data-state="checked"] {
      background-color: #71903E;

      .canopy_switch_thumb {
        transform: translateX(24px);
      }
    }

    .canopy_switch_thumb {
      display: block;
      width: 21px;
      height: 21px;
      background-color: #fff;
      border-radius: 9999px;
      transition: transform 100ms;
      transform: translateX(2px);
      will-change: transform;
      box-shadow: 0 2px 10px #00000024;
    }
  }

  .custom_modal_title_border {
    border-bottom: 1px solid #DFDFE1;
  }

  .dimensions_modal_container {
    .custom_modal_pergola_dimensions_container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;
      background-color: #F4F3F3;
      border-radius: 6px;
    }

    .custom_dimensions_modal_tab_button {
      position: relative;
      width: 83px;
      color: #393B42;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      text-align: center;
    }

    .custom_dimensions_modal_tab_button_active {
      position: relative;
      width: 83px;
      color: #71903E;
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;
      text-align: center;
    }
  }

  .notice_dimensions_modal_container {
    grid-template-columns: 30px 1fr;
    grid-gap: 15px;
    align-items: center;
    background-color: #F4F3F3;
    border-radius: 6px;
  }

  .notice_modal_container {
    grid-template-columns: 30px 1fr;
    grid-gap: 15px;
    align-items: flex-start;
    background-color: #F4F3F3;
    border-radius: 6px;
  }

  .notice_mobile_modal_container {
    background-color: #F4F3F3;
    border-radius: 6px;
  }

  .cart_modal_trigger_button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 58px;
    padding: 0 15px;
    height: 44px;
    width: 208px;
    background-color: #71903E;
    color: #fff;
  }

  .summary_border {
    border-bottom: 0.25px solid rgba(57, 59, 66, 0.5);
  }

  .cart_modal_container::-webkit-scrollbar {
    width: 7px;
  }

  .cart_modal_container::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: none;
    border-top: 150px solid #71903E;
  }

  .cart_modal_container {
    height: auto;
    max-height: 65vh;
    overflow: auto;
    padding-bottom: 10rem;

    .cart_modal_grid {
      display: grid;
      grid-template-columns: 1.3fr 1fr;
      grid-template-areas: "canvas summary"
                            "buttons shipping";
      margin: 2rem 0;

      .cart_modal_canvas {
        grid-area: canvas;
      }

      .cart_modal_summary {
        grid-area: summary;
        box-shadow: 0 4px 40px rgba(53, 52, 63, 0.1);
        border-radius: 10px;

        .checkout_modal_summary_border {
          border-bottom: 1px solid #F4F3F3;
        }
      }

      .cart_grid_floating_buttons_container {
        grid-area: buttons;
      }

      .cart_grid_shipping_container {
        grid-area: shipping;
      }
    }
  }

  .cart_modal_sticky_bottom {
    .add_to_cart_modal_button {
      border: 1px solid #fff;
      border-radius: 58px;

      &:hover {
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.25);
      }

      &:disabled {
        opacity: 0.7;
      }
    }

    .checkout_modal_button {
      border: 1px solid #71903E;
      border-radius: 58px;

      &:hover {
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.25);
      }

      &:disabled {
        opacity: 0.7;
      }

      &.no-hover {
        pointer-events: none;
      }
    }
  }

  .home_modal_container {
    .stay_button {
      border-radius: 20px;
      padding: 8px 2.2rem;
    }
  }

  .clipboard_copy_container {
    display: grid;
    grid-template-columns: 20px 1fr;
    justify-content: space-around;
    align-items: center;
    background-color: #F4F3F3;
    border-radius: 7px;
    cursor: pointer;
  }

  // Desktop
  .desktop_container {
    .notice_boxes_container {
      position: absolute;

      .notice_container {
        grid-template-columns: 30px 1fr 15px;
        grid-gap: 10px;
        align-items: flex-start;
        background-color: #fff;
        border-left: 7px solid #71903E;
        border-radius: 6px;
      }
    }

    .floating_buttons_container {
      position: absolute;
      flex-direction: column;
      justify-content: space-between;
      left: 2rem;
      top: 11%;
      height: 80vh;

      @media screen and (max-height: 1079px) {
        height: 75vh;
      }

      @media screen and (max-height: 680px) {
        top: 15%;
        height: 70vh;
      }

      .floating_button_container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 39px;
        height: 39px;
        background-color: #fff;
        border-radius: 50%;
        outline: none;
        box-shadow: 0 0 10px rgba(57, 59, 66, 0.2);
        transition: all .2s ease;
        cursor: pointer;
        -webkit-appearance: none;

        &.help {
          width: 109px;
          height: 35px;
          border-radius: 41px;
        }

        .floating_button_text {
          position: absolute;
          display: none;
          animation-name: textOpacity;
          animation-duration: 1s;
          transition: all 2s ease;
          width: 110px;
          left: 25%;

          &.ar {
            left: 35%;
          }

          &.help {
            left: 50%;
          }
        }

        &:hover {
          justify-content: start;
          width: 156px;
          border-radius: 34px;

          &.ar {
            width: 117px;
          }

          &.help {
            width: 244px;
            border-radius: 41px 41px 0 41px;
          }

          .floating_button_image {
            margin-left: 9px;
          }

          .floating_button_text {
            display: flex;
            opacity: 1;
          }

          .floating_button_help_text {
            margin-left: 15px;
          }
        }
      }
    }

    .floating_buttons_container_cart_modal {
      .floating_button_container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 39px;
        height: 39px;
        background-color: #fff;
        border-radius: 50%;
        outline: none;
        box-shadow: 0 0 10px rgba(57, 59, 66, 0.2);
        transition: all .2s ease;
        cursor: pointer;
        -webkit-appearance: none;

        .floating_button_text {
          position: absolute;
          display: none;
          animation-name: textOpacity;
          animation-duration: 1s;
          transition: all 2s ease;
          width: 110px;
          left: 25%;

          &.ar {
            left: 35%;
          }
        }

        &:hover {
          justify-content: start;
          width: 156px;
          border-radius: 34px;

          &.ar {
            width: 117px;
          }

          .floating_button_image {
            margin-left: 9px;
          }

          .floating_button_text {
            display: flex;
            opacity: 1;
          }

          .floating_button_help_text {
            margin-left: 15px;
          }
        }
      }
    }

    .app_version {
      position: absolute;
      right: 1px;
      bottom: 1px;
    }

    .main_grid_layout {
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-template-rows: [header] 65px [canvas] 1fr [summary] 84px;
      row-gap: 10px;
      grid-template-areas: "header controls";
      padding: 3rem 2rem 1.5rem 2rem;

      .tab_title {
        &:hover {
          color: #71903E;
          cursor: pointer;
        }
      }

      .hover_color {
        &:hover {
          color: #71903E;
        }
      }

      .header {
        grid-row-start: header;
        grid-row-end: header;

        .custom_border {
          position: relative;
        }

        .custom_border::after {
          content: '';
          position: absolute;
          background: rgba(113, 144, 62, 0.5);
          top: 1rem;
          left: 0;
          width: 100%;
          height: 50%;
          z-index: -1;
        }
      }

      .canvas {
        grid-row-start: canvas;
        grid-row-end: canvas;
      }

      .controls {
        grid-area: controls;

        .desktop_popup_link_margin {
          margin-bottom: 2.5rem;

          @media screen and (max-height: 680px) {
            margin-bottom: 0.25rem;
          }
        }

        .pergola_design_container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          justify-content: center;

          .pergola_design_item {
            position: relative;
            justify-self: center;
            width: 80%;
            background-color: white;
            border-radius: 6px;

            @media screen and (max-height: 680px) {
              width: 60%;


              &:hover {
                background-color: white !important;
              }
            }

            &:hover {
              background-color: white !important;
            }

            .pergola_design_img {
              //width: 100%;
              //height: auto;
            }
          }

          .pergola_design_item_indicator {
            position: absolute;
            display: block;
            top: -3px;
            left: -3px;
            width: 104%;
            height: 105%;
            border-radius: 6px;
            background-color: transparent;
            border: 2px solid #71903E;
          }

          .pergola_design_selected_label {
            font-weight: 700;
          }

          .pergola_design_label {
            font-weight: 400;
          }
        }

        .pergola_design_color_container {
          .pergola_design_color_label_wrapper {
            position: relative;

            .tooltip_text {
              position: absolute;
              visibility: hidden;
              width: 120px;
              background-color: black;
              color: #fff;
              text-align: center;
              padding: 3px 0;
              border-radius: 6px;
              z-index: 1;
              left: 0;
              bottom: 0;
            }

            &:hover {
              .tooltip_text {
                visibility: visible;
              }
            }
          }

          .pergola_design_color_item {
            position: relative;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            border: 1px solid transparent;

            &:hover {
              border-color: #71903E;
            }

            &.disabled {
              border-color: transparent;
              cursor: not-allowed;
            }

            .pergola_design_color_circle {
              position: relative;
              width: 40px;
              height: 40px;
              top: 0;
              left: 2px;
              border-radius: 50%;

              .cross_icon_container {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 0;
              }
            }
          }

          .pergola_design_color_indicator {
            position: absolute;
            display: block;
            top: -1px;
            left: -1px;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            border: 1px solid #71903E;
            background-color: transparent;
          }

          .pergola_design_color_label {
            font-weight: 300;
            color: #393B42;
            margin-top: 10px;
            cursor: pointer;
          }

          .pergola_design_color_selected_label {
            font-weight: 700;
            color: #393B42;
            margin-top: 10px;
            cursor: pointer;
          }

          .disabled-color-label {
            &:hover {
              cursor: not-allowed;
            }
          }
        }

        .height_dimensions_label_margin {
          margin-top: 2.5rem;

          @media screen and (max-height: 680px) {
            margin-top: 0;
          }
        }

        .canopy_tab_content_container {
          .canopy_switch_container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;
            margin-top: 2rem;
            background: rgba(113, 144, 62, 0.1);
            border: 1px solid #71903E;
            border-radius: 6px;

            .canopy_switch_root {
              position: relative;
              width: 42px;
              height: 25px;
              cursor: pointer;
              border-radius: 9999px;
              caret-color: transparent;
              box-shadow: 0 2px 10px #00000024;
              -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

              &[data-state="checked"] {
                background-color: #71903E;

                .canopy_switch_thumb {
                  transform: translateX(19px);
                }
              }

              .canopy_switch_thumb {
                display: block;
                width: 21px;
                height: 21px;
                background-color: #fff;
                border-radius: 9999px;
                transition: transform 100ms;
                transform: translateX(2px);
                will-change: transform;
                box-shadow: 0 2px 10px #00000024;
              }
            }
          }

          .canopy_colors_radio_container {
            .canopy_colors_radio_group_item {
              position: relative;
              width: 46px;
              height: 46px;
              border-radius: 50%;
              border: 1px solid transparent;

              &:hover {
                border-color: #71903E;
              }

              .canopy_color_circle {
                position: relative;
                top: 0;
                left: 2px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }

              .canopy_color_indicator {
                display: block;
                position: absolute;
                top: -1px;
                left: -1px;
                width: 46px;
                height: 46px;
                border-radius: 50%;
                background-color: transparent;
                border: 1px solid #71903E;
              }
            }

            .canopy_design_color_label {
              font-weight: 300;
              color: #393B42;
              margin-top: 10px;
              cursor: pointer;
            }

            .canopy_design_color_selected_label {
              font-weight: 700;
              color: #393B42;
              margin-top: 10px;
              cursor: pointer;
            }
          }
        }
      }

      .summary {
        grid-row-start: summary;
        grid-row-end: summary;

        @media screen and (max-width: 1350px) {
          min-height: 100px;
        }

        @media screen and (max-width: 1150px) {
          min-height: 120px;
          margin-top: -1rem;
        }

        .pergola_summary_grid {
          display: grid;
          grid-template-columns: 1fr 2fr 1fr;
          grid-gap: 10px;
          grid-template-areas: "structure dimensions beams";
          padding: 0 2rem;
          border-radius: 10px;

          @media screen and (max-width: 1350px) {
            grid-template-columns: 1fr 1fr 1fr;
          }

          @media screen and (max-width: 1150px) {
            grid-template-columns: 1fr 1fr 0.7fr;
          }

          @media screen and (max-width: 1170px) {
            padding: 0 10px;
          }

          .pergola_summary_structure {
            grid-area: structure;
            width: 100%;
            border-right: 1px solid #f5f5f5;
          }

          .pergola_summary_dimensions {
            grid-area: dimensions;
            display: flex;
            justify-content: center;
            width: 100%;

            .custom_dimensions_flex {
              @media screen and (max-width: 1350px) {
                flex-direction: column;
              }
            }
          }

          .pergola_summary_beams {
            grid-area: beams;
            display: flex;
            justify-content: flex-end;
            width: 100%;
            border-left: 1px solid #f5f5f5;

            .beams_and_weight_custom_flex {
              display: flex;
              align-items: center;

              .beams_summary_text {
                margin-left: 1rem;
              }

              @media screen and (max-width: 1248px) {
                text-align: center;
                flex-direction: column;

                .beams_summary_text {
                  margin-left: 0;
                }
              }
            }
          }

          .pergola_summary_text {
            color: #393B42;
            font-size: 14px;
            font-weight: 400;
            font-family: "Roboto", sans-serif;
          }

          .pergola_summary_dimensions_text {
            margin-left: 1rem;
            color: #393B42;
            font-size: 14px;
            font-weight: 700;

            @media screen and (max-width: 1350px) {
              font-size: 12px;
              font-weight: 700;
              margin-left: 0;
            }

            span {
              color: #393B42;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  /* Mobile */
  .mobile_layout_container {
    grid-template-columns: 100vw;
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    grid-template-rows: [header] 66px [canvas] 1fr [controls] 1.6fr [button] 0.1fr;
    grid-template-areas:
        "header"
        "canvas"
        "controls"
        "button";

    @media not all and (min-resolution: .001dpcm) {
      height: 90vh;
    }

    .mobile_grid_header_item {
      grid-area: header;
    }

    .mobile_tab_button {
      font-weight: 200;
      font-size: 14px;
    }

    .mobile_tab_active_button {
      font-weight: 800;
      font-size: 14px;
      color: #71903E;
    }

    .mobile_tab_bar_header_text {
      width: 100%;
    }

    .floating_buttons_container {
      position: absolute;
      flex-direction: column;
      justify-content: space-between;
      right: 0.875rem;
      top: 2rem;
      height: 81%;

      &.summary_tab {
        top: 0.875rem;
        height: 88%;
      }

      .floating_button_container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 0 10px rgba(57, 59, 66, 0.2);
      }
    }

    .mobile_canvas_container {
      grid-area: canvas;
      position: relative;
    }

    .mobile_pergola_settings_container {
      grid-area: controls;
      border-top-left-radius: 17px;
      border-top-right-radius: 17px;
      background-color: #fff;
      box-shadow: 0 4px 40px rgba(53, 52, 63, 0.2);
      overflow: auto;

      .pergola_design_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;

        .pergola_design_item {
          position: relative;
          justify-self: center;
          width: 50%;
          background-color: white !important;
          border-radius: 2px;

          &:hover {
            background-color: white !important;
          }
        }

        .pergola_design_item_indicator {
          position: absolute;
          display: block;
          top: -3px;
          left: -3px;
          width: 103%;
          height: 105%;
          border-radius: 6px;
          background-color: transparent;
          border: 2px solid #71903E;

          @media screen and (max-width: 500px) {
            width: 106%;
            height: 109%;
          }
        }

        .pergola_design_selected_label {
          font-weight: 600;
        }

        .pergola_design_label {
          font-weight: 300;
        }
      }

      .pergola_design_color_container {
        .pergola_design_color_item {
          position: relative;
          width: 39px;
          height: 39px;
          border-radius: 50%;
          border: 1px solid transparent;

          &:hover {
            border-color: #71903E;
          }

          &.disabled {
            border-color: transparent;
          }

          .pergola_design_color_circle {
            position: relative;
            width: 33px;
            height: 33px;
            top: 0;
            left: 2px;
            border-radius: 50%;

            .cross_icon_container {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              top: 0;
              width: 40px;
              height: 33px;
              left: -3.5px;
            }
          }
        }

        .pergola_design_color_indicator {
          position: absolute;
          display: block;
          top: -1px;
          left: -1px;
          width: 39px;
          height: 39px;
          border-radius: 50%;
          border: 1px solid #71903E;
          background-color: transparent;
        }

        .pergola_design_color_label {
          font-size: 12px;
          font-weight: 300;
          color: #393B42;
          margin-top: 5px;
          cursor: pointer;
        }

        .pergola_design_color_selected_label {
          font-size: 12px;
          font-weight: 600;
          color: #393B42;
          margin-top: 5px;
          cursor: pointer;
        }
      }

      .notice_mobile_container {
        background-color: #F4F3F3;
        border-radius: 6px;

        .notice_mobile_grid {
          grid-template-columns: 30px 1fr 15px;
          grid-gap: 10px;
          align-items: flex-start;
        }
      }

      .custom_dimension_mobile_input {
        width: 51.5px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-color: #DFDFE1;
        padding: 5px;

        &:focus-within {
          .custom_dimension_feet_input,
          .custom_dimension_inches_input {
            border-color: #71903E;
          }
        }

        &:focus {
          outline: none;
          border-color: #71903E;
        }
      }

      .custom_dimension_feet_input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-left: 1px solid #DFDFE1;
        border-right: 0.5px solid #DFDFE1;
      }

      .custom_dimension_inches_input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-right: 1px solid #DFDFE1;
        border-left: 0.5px solid #DFDFE1;
      }

      .canopy_tab_content_container {
        .canopy_switch_container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem;
          background: rgba(113, 144, 62, 0.1);
          border: 1px solid #71903E;
          border-radius: 6px;

          .canopy_switch_root {
            position: relative;
            width: 42px;
            height: 25px;
            border-radius: 9999px;
            caret-color: transparent;
            box-shadow: 0 2px 10px #00000024;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            &[data-state="checked"] {
              background-color: #71903E;

              .canopy_switch_thumb {
                transform: translateX(19px);
              }
            }

            .canopy_switch_thumb {
              display: block;
              width: 21px;
              height: 21px;
              background-color: #fff;
              border-radius: 9999px;
              transition: transform 100ms;
              transform: translateX(2px);
              will-change: transform;
              box-shadow: 0 2px 10px #00000024;
            }
          }
        }

        .canopy_colors_radio_container {
          .canopy_colors_radio_group_item {
            position: relative;
            width: 39px;
            height: 39px;
            border-radius: 50%;
            border: 1px solid transparent;

            &:hover {
              border-color: #71903E;
            }

            .canopy_color_circle {
              position: relative;
              top: 0;
              left: 2px;
              width: 33px;
              height: 33px;
              border-radius: 50%;
            }

            .canopy_color_indicator {
              display: block;
              position: absolute;
              top: -1px;
              left: -1px;
              width: 39px;
              height: 39px;
              border-radius: 50%;
              background-color: transparent;
              border: 1px solid #71903E;
            }
          }

          .canopy_design_color_label {
            font-weight: 300;
            color: #393B42;
            margin-top: 10px;
            cursor: pointer;
          }

          .canopy_design_color_selected_label {
            font-weight: 700;
            color: #393B42;
            margin-top: 10px;
            cursor: pointer;
          }
        }
      }
    }

    .mobile_cart_bottom_bar_container {
      grid-area: button;
      width: 100%;
      position: sticky;
      bottom: 0;
      z-index: 999;

      &.summary_bar {
        height: 117px;
      }

      .mobile_cart_price_button_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        background-color: #393B42;
      }
    }

    .mobile_next_cart_button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 58px;
      padding: 0 15px;
      font-size: 14px;
      line-height: 27px;
      font-weight: 500;
      height: 38px;
      width: 159px;
      background-color: #71903E;
      color: #fff;
      cursor: pointer;

      &:disabled {
        opacity: 0.7;
      }
    }
  }
}

.alert-dialog_fullScreen {
  width: 100vw !important;
  height: 100% !important;
  max-height: unset !important;
  border-radius: 0 !important;
}

@media screen and (min-width: 1067px) {
  .alert-dialog_fullScreen {
    width: 100vw !important;
    height: 80% !important;
    max-height: unset !important;
    border-radius: 10px !important;
  }
}

.bullet-list li::before { /* add the new bullet point */
  display: inline-block;
  content: '';
  -webkit-border-radius: 0.375rem;
  border-radius: 0.375rem;
  height: 0.5rem;
  width: 0.5rem;
  margin-right: 0.5rem;
  background-color: #71903E;
}


.button-interaction {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.imageBox {
  background: white;
}

.swatchBorder {
  @apply border border-text
}
